/*
    UTM settings for webflow
*/
frappe.provide("landing.webflow.utm");

$.extend(landing.webflow.utm, {
    setup_handlers: function(){
        $("a:not([element-type])").on("click", (e)=>{
            let utm_param = landing.webflow.utm.get_utm_param();
            let partner_code = landing.webflow.partner.get_partner_code();
            let $ele = landing.webflow.utils.extract_a_tag(e, true)
            let actual_url = $ele.attr("href");
            if(actual_url && actual_url.indexOf("?") >= 0 && utm_param){
                actual_url = `${actual_url}&${utm_param}`;
                if(partner_code){
                    actual_url = `${actual_url}&referral_code=${partner_code}`;    
                }
            }else if(actual_url && utm_param){
                actual_url = `${actual_url}?${utm_param}`
                if(partner_code){
                    actual_url = `${actual_url}&referral_code=${partner_code}`;
                }
            }else if(actual_url && partner_code){
                actual_url  = `${actual_url}${actual_url.indexOf("?") >= 1?'&':'?'}referral_code=${partner_code}`;
            }

            if(actual_url){
                window.location.href = actual_url;
            }
            return false;
        });
    },

    get_utm_args: function(){
        let params = (new URL(document.location)).searchParams;
        let KEYS = ["utm_source", "utm_medium", "utm_campaign",
                    "utm_content", "utm_term"];
        let args = {};
        KEYS.forEach((key, idx)=>{
            if(params.get(key)){
                args[key] = params.get(key);
            }
        });
        return args;
    },

    get_utm_param: function(){
        let args = landing.webflow.utm.get_utm_args();
        if(args.utm_source){
            return $.param(args);
        }
        return "";
    }
});

landing.webflow.utm.setup_handlers();