/*
    Partner Utilities
*/
frappe.provide("landing.webflow.partner");

$.extend(landing.webflow.partner, {
    get_partner_code: function(){
        let params = (new URL(document.location)).searchParams;
        return params.get("referral_code")?params.get("referral_code"): '';
    }
});