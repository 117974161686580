frappe.provide("landing.webflow.utils");

$.extend(landing.webflow.utils, {

    extract_a_tag: function(e, prevent_default=false){
        if(prevent_default){
            e.preventDefault();
        }
        let $ele = $(e.target);
        if(!$ele.is("a")){
            $ele = $ele.parent();
            if(!$ele.is("a")){
                $ele = $ele.parent();
            }
        }
        return $ele;
    },

    get_business_type(){
        let path_name = window.location.pathname;
        let partner_type = "";
        let application_type = "";
        let business_type = "";
        let commercial_pages = ["/business", "/business-auto", "/business-bop", "/business-workers-comp",
            "/business-pro-liability", "/business-general-liability", "/business-financing"];

        if(path_name.indexOf("partners") >= 0){
            if(path_name.indexOf("/automotive-partners") >= 0){
                partner_type = "Automotive"
            }else if(path_name.indexOf("/realtor-partners") >= 0
                || path_name.indexOf("/realestate-partners") >= 0
                || path_name.indexOf("/real-estate-partners") >= 0){
                partner_type = "Real Estate";
            }else if(path_name.indexOf("/property-manage-partners") >= 0
                || path_name.indexOf("/property-manager-partners") >= 0){
                partner_type = "Property Manager";
            }else if(path_name.indexOf("/lender-partners") >= 0){
                partner_type = "Lenders";
            }
        }else if(path_name.indexOf("insurance") >= 0){
            if(path_name.indexOf("/auto-insurance") >= 0 ||
                path_name.indexOf("/motorcycle-insurance") >= 0){
                application_type = "personalauto";
            }else if(path_name.indexOf("/homeowners-insurance") >= 0){
                application_type = "homeowners"
            }else if(path_name.indexOf("/condo-insurance") >= 0){
                application_type = "condo";
            }else if(path_name.indexOf("/renters-insurance") >= 0){
                application_type = "renters";
            }else if(path_name.indexOf("/motorcycle-insurance") >= 0){
                application_type = "personalauto";
            }else if(path_name.indexOf("/watercraft-insurance") >= 0){
                application_type = "watercraft";
            }
        }else if(commercial_pages.indexOf(path_name) >= 0){
            if(path_name.indexOf("/business-auto") >= 0){
                business_type = "Commercial Auto";
            }else if(path_name.indexOf("/business-bop") >= 0){
                // Business Owner Policy
                business_type = "Business Owners Policy";
            }else if(path_name.indexOf("/business-workers-comp") >= 0){
                // cover worker employee
                business_type = "Workers Comp";
            }else if(path_name.indexOf("/business-pro-liability") >= 0){
                business_type = "Professional Liability";
            }else if(path_name.indexOf("/business-general-liability") >= 0){
                business_type = "General Liability";
            }else if(path_name.indexOf("/business-financing") >= 0){
                business_type = "Business Lending";
            }
        }else if(path_name.indexOf("/real-estate") >= 0){
            business_type = "Real Estate";
        }

        return business_type;
    }
});