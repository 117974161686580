/*
    Server redirections from webflow website to portal
*/

frappe.provide("landing.webflow.redirections");

$.extend(landing.webflow.redirections, {

        link_redirection_handlers: function(){
            landing.webflow.redirections.setup_portal_redirections();
            landing.webflow.redirections.setup_appointment_redirections();
            landing.webflow.redirections.see_quotes();
            landing.webflow.redirections.business_lending_phone_number();
        },

        setup_portal_redirections: function(){
            $("a[element-type='portal-link']").on("click", (e)=>{
                let $ele = $(e.currentTarget);
                let path_name = window.location.pathname;
                let partner_type = "";
                let application_type = "";
                let commercial_pages = ["/business", "/business-auto", "/business-bop", "/business-workers-comp",
                    "/business-pro-liability", "/business-general-liability", "/business-financing"];
                
                let personal_pages = ["/personal-auto", "/personal-homeowners", "/personal-condo", "/condo-insurance",
                                        "/personal-renters", "/renters-insurance", "/personal-motorcycle", "/motorcycle-insurance",
                                        "/personal-watercraft", "/watercraft-insurance"];
                if(path_name.indexOf("partners") >= 0){
                    if(path_name.indexOf("/automotive-partners") >= 0){
                        partner_type = "Automotive"
                    }else if(path_name.indexOf("/realtor-partners") >= 0
                        || path_name.indexOf("/realestate-partners") >= 0
                        || path_name.indexOf("/real-estate-partners") >= 0){
                        partner_type = "Real Estate";
                    }else if(path_name.indexOf("/property-manage-partners") >= 0
                        || path_name.indexOf("/property-manager-partners") >= 0){
                        partner_type = "Property Manager";
                    }else if(path_name.indexOf("/lender-partners") >= 0){
                        partner_type = "Lenders";
                    }
                    if(partner_type){
                        let param = `partner-type=${partner_type}`
                        landing.webflow.redirections.make_redirect("partner-signup", param);
                    }
                }else if(personal_pages.indexOf(path_name) >= 0){
                    if(path_name.indexOf("/personal-auto") >= 0){
                        application_type = "personalauto";
                    }else if(path_name.indexOf("/personal-homeowners") >= 0){
                        application_type = "homeowners"
                    }else if(path_name.indexOf("/personal-condo") >= 0|| path_name.indexOf("/condo-insurance") >= 0){
                        application_type = "condo";
                    }else if(path_name.indexOf("/personal-renters") >= 0 || path_name.indexOf("/renters-insurance") >= 0){
                        application_type = "renters";
                    }else if(path_name.indexOf("/personal-motorcycle") >= 0 || path_name.indexOf("/motorcycle-insurance") >= 0){
                        application_type = "personalauto";
                    }else if(path_name.indexOf("/personal-watercraft") >= 0 || path_name.indexOf("/watercraft-insurance") >= 0){
                        application_type = "watercraft";
                    }
                    if(application_type){
                        landing.webflow.redirections.redirect_to_appt_page(application_type);
                    }
                }else if(commercial_pages.indexOf(path_name) >= 0){
                    let page_name = "commercialintake";
                    let business_type = null;
                    if($ele && $ele.attr("form-type") === "Business Lending"){
                        page_name = "businesslending";
                    }else{
                        if(path_name.indexOf("/business-auto") >= 0){
                            page_name = "commercialauto";
                        }else if(path_name.indexOf("/business-bop") >= 0){
                            // Business Owner Policy
                            business_type = "Business Owners Policy";
                        }else if(path_name.indexOf("/business-workers-comp") >= 0){
                            // cover worker employee
                            business_type = "Workers Comp";
                        }else if(path_name.indexOf("/business-pro-liability") >= 0){
                            business_type = "Professional Liability";
                        }else if(path_name.indexOf("/business-general-liability") >= 0){
                            business_type = "General Liability";
                        }else if(path_name.indexOf("/business-financing") >= 0){
                            business_type = "";
                            page_name = "businesslending";
                        }
                    }
                    if(page_name){
                        landing.webflow.redirections.redirect_to_appt_page(business_type);
                    }
                }else{

                }
                return false;
            });
        },

        setup_appointment_redirections: function(){
            $("a[element-type='appointment-link']").on("click", (e)=>{
                e.preventDefault();
                landing.webflow.redirections.redirect_to_appt_page();
                return false;
            });
        },

        redirect_to_appt_page(temp){
            let business_type = landing.webflow.utils.get_business_type();
            landing.webflow.redirections.make_redirect("appt",
                temp?`business-type=${temp}`:'');

        },

        see_quotes: function(){
            $(".review-quote-redirection").on("click", (e)=>{
                e.preventDefault();
                landing.webflow.redirections.make_redirect("quotes/see-quotes");
                return false;
            });
        },

        business_lending_phone_number(){
            $("a[element-type='business-phone-number']").on("click", (e)=>{
                e.preventDefault();
                landing.webflow.redirections.show_phone_popup();
                return false;
            });
        },

        get_portal_base_url: function(){
            let server = "https://www.poweredbybond.com";
            let host = window.location.host;
            if(host == "trybond.com" || host == "www.trybond.com"){
                server = "https://www.poweredbybond.com";
            }else{
                server = "https://clientportal.bond";
            }
            return server;
        },

        make_redirect: function(path, param){
            if(path == "#"){
                path = "";
            }
            let base_url = landing.webflow.redirections.get_portal_base_url();
            let utm_param = landing.webflow.utm.get_utm_param();
            let partner_code = landing.webflow.partner.get_partner_code();
            let url = base_url;
            let has_param=false, has_utm_param=false;
            if(path){
                url = `${base_url}/${path}`;
            }
            if(param){
                url = `${url}?${param}`;
                has_param = true;
            }
            if(utm_param){
                url = `${url}${has_param?'&':'?'}${utm_param}`;
                has_utm_param=true;
            }

            if(partner_code){
                url = `${url}${has_param || has_utm_param?'&':'?'}referral_code=${partner_code}`;
            }

            window.location.href = url;
        },

        show_phone_popup(){
            window.open("tel:+12122036463")
        }
});
// landing server redirections
landing.webflow.redirections.link_redirection_handlers();